<template>
    <div class='abilityDetails'>
        <div class="content">
            <!-- 时间 -->
            <div class="time top">
              <svg-icon icon-class="back" class="arr arr-left" @click="getDate(false)"/>
              <div class="current">{{ current }}</div>
              <svg-icon v-show="current != today" icon-class="back" class="arr arr-right"  @click="getDate(true)"/>
            </div>
            <!-- 时间段 -->
            <div class="timeSlot">
                {{ currentTime }} 平均
            </div>
            <div class="percentage">{{ currentBloodOxygen }}</div>
            <!-- 折线图 -->
            <div class="echart">
                <div id="BrokenLine" class="histogram"></div>
            </div>
            <!-- 提示 -->
            <div class="tips">
                <div class="less"> ＜90% </div>
                <div class="greater">90%-100%</div>
            </div>
            <div class="tipsNum">
                <div class="mini">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最低值</div>
                    </div>
                    <div class="num">{{ valuemin }}</div>
                </div>
                <div class="average">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>平均值</div>
                    </div>
                    <div class="num">{{ valueavg }}</div>
                </div>
                <div class="max">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最大值</div>
                    </div>
                    <div class="num">{{ valuemax }}</div>
                </div>
            </div>
        </div>

        <div class="content history-box">
            <!-- 时间 -->
            <div class="time">
                <!-- <svg-icon icon-class="back" /> -->
                <div class="current">血氧走势</div>
            </div>
            <!-- 折线图 -->
            <div class="echart">
                <div id="historyBrokenLine" class="histogram"></div>
            </div>
            <HistoryBar @onIndexCahnge="onIndexCahnge"></HistoryBar>
            <!-- 提示 -->
            <div class="tips">
                <div class="less"> ＜90% </div>
                <div class="greater">90%-100%</div>
            </div>
            <div class="tipsNum">
                <div class="mini">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最低值</div>
                    </div>
                    <div class="num">{{ historyBloodOxygenMin }}%</div>
                </div>
                <div class="max">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最大值</div>
                    </div>
                    <div class="num">{{ historyBloodOxygenMax }}%</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { current } from '@/util/validate'
import * as echarts from 'echarts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent
} from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { GetWatchTypeByDate, GetWatchTypeByHistoryDate} from '@/api/index'
import { mapGetters } from 'vuex'
import HistoryBar from '../../components/tabbar/HistoryBar.vue'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
])

export default {
  components: {
    HistoryBar
  },
  data () {
    return {
      current: current(),
      today: current(),
      currentBloodOxygen: '',
      valueavg: null,
      valuemax: null,
      valuemin: null,
      xData: [],
      yData: [],
      currentTime: '',
      currentActiveIndex:0,
      historyBloodOxygenMax:0,
      historyBloodOxygenMin:0,
      historyXData:[],
      historyYData:[],
    }
  },
  props: {

  },
  created () {

  },
  mounted () {
    this.getBloodOxygenData()
    this.getHistoryBloodOxygenData()
  },
  methods: {
    getBloodOxygenData(){
        GetWatchTypeByDate({
            userId: this.userid,
            time: this.current,
            type: 3// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
        }).then(res => {
            // console.log('----', res)
            const _data = res.data
            this.valueavg = _data.valueavg
            this.valuemax = _data.valuemax
            this.valuemin = _data.valuemin
            this.xData = _data.timelist[0].time
            this.yData = _data.timelist[0].value
            this.$nextTick(() => {
                this.initializationBrokenLine()
            })
        }).catch(err=>{
          this.xData = []
          this.yData = []
          this.$nextTick(() => {
            this.initializationBrokenLine()
          })
        })
    },
    // 初始化折线图
    initializationBrokenLine () {
      if (!document.getElementById('BrokenLine')) {
        return
      }
      var chartDom = document.getElementById('BrokenLine')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        legend: {},
        tooltip: {
            trigger: 'axis',
            formatter:  (value)=> {
                let xIndex = value[0].dataIndex
                this.currentBloodOxygen = this.yData[xIndex] + '%'
                this.currentTime = `${this.xData[xIndex]}-${this.xData[xIndex + 1]}`
            }
        },
        xAxis: {
          type: 'category',
          data:this.xData,
          axisLine: {
            show: false // 是否显示坐标轴
          },
          axisTick: {
            show: false // 是否显示坐标轴刻度
          },
          splitLine: { show: false },// 去除网格线
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            interval: Math.ceil(this.xData.length / 4),
            margin:20
            // formatter:(v, i)=> {
            //     if (v == '00:00' || v == '06:00' || v == '12:00'|| v == '18:00' || v == '24:00') {
            //         return v
            //     }
            //     return ''
            // },
          }
        },
        yAxis: {
          type: 'value',
          scale: true,
          // max : 100,
          min: 0,
          splitNumber: 2,
          axisLabel: {
            formatter: '{value}%'
          }
        },
        grid: {
          left: '0',
          bottom: '3%',
          containLabel: true
        },
        series: [{
          data: this.yData,
          type: 'bar',
          itemStyle: {
            normal: {
              color: function (params) {
                if (params.value < 90) {
                  return '#D330FF'
                } else {
                  return '#22BE96'
                }
              }
            }
          }

        }]
      }
      option && myChart.setOption(option)
    },
    //获取历史血氧数据
    getHistoryBloodOxygenData(){
        GetWatchTypeByHistoryDate({
            userId: this.userid,
            timeType: this.currentActiveIndex+1,//1近一周 2近一个月 3近三个月 4近半年 5近一年
            dateTtpe: 3// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
        }).then(res => {
            const _data = res.data
            // console.log('------',_data)
            let _list = _data.daysList.reverse()
            this.historyXData = _list.map(item=>{
                return item.time;
            })
            this.historyYData = _list.map(item=>{
                return item.value;
            })
            this.historyBloodOxygenMax= _data.bloodOxygenMax
            this.historyBloodOxygenMin = _data.bloodOxygenMin
            this.$nextTick(() => {
                this.initHistoryBrokenLine()
            })
        })
    },
    initHistoryBrokenLine(){
      if (!document.getElementById('historyBrokenLine')) {
        return
      }
      let chartDom = document.getElementById('historyBrokenLine')
      let myChart = echarts.init(chartDom)
      let option
      option = {
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: this.historyXData,
        //   nameLocation: "end",
          boundaryGap: false,
          axisLine: {
            show: false // 是否显示坐标轴
          },
          axisTick: {
            show: false // 是否显示坐标轴刻度
          },
          splitLine: { show: false },// 去除网格线
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            margin:'20',
            formatter:(v, i)=> {
                return this.currentActiveIndex == 0 || this.currentActiveIndex == 1? v.substring(5):v
            },
          }
        },
        yAxis: {
          type: 'value',
          scale: true,
          // max : 100,
          min: 0,
          splitNumber: 3,
        },
        grid: {
          left: "10%",
          right: "7%",
          bottom:"20%"
        },
        series: [
          {
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#FF9281'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF9180'
                },
                {
                  offset: 1,
                  color: '#FFC9C0'
                }
              ])
            },
            data: this.historyYData
          }
        ]
      }
      option && myChart.setOption(option)
    },
    onIndexCahnge(index){
      this.currentActiveIndex = index
      this.getHistoryBloodOxygenData()
    },
    getDate(flag){
      let t = flag? new Date(this.current).getTime()+86400000: new Date(this.current).getTime()-86400000
      this.current = current(new Date(t))
      this.getBloodOxygenData()
    },
  },
  watch: {

  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  }
}
</script>
<style lang='scss' scoped>
.abilityDetails {
    height: 100%;
    padding: 0 20px;
    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/modularBag2.png') no-repeat;
    background-size: 100%;
    background-color: #f7f7f7;
    .content {
        width: 100%;
        border-radius: 15px;
        background: #fff;
        // height: 80%;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        &.history-box{
            .tipsNum{
                // padding: 0 20px;
                box-sizing: border-box;
                .mini,.max{
                    width:45%;
                }
            }
        }
        .time {
            color: #868686;
            display: flex;
            align-items: center;
            font-size: 16px;
            position: relative;
            .arr-left{
              position: absolute;
              top:50%;
              transform: translate(0, -50%);
              left:-10px;
            }
            .arr-right{
              position: absolute;
              top:50%;
              transform: translate(0, -50%) rotate(180deg);
              right:-10px;
            }
            &.top{
              margin-bottom: 20px;
              display: flex;
              align-content: center;
            }
            .current {
                font-size: 20px;
                font-weight: 700;
                margin: 0 10px;
            }
        }
        .echart {
            width: 100%;
            position: relative;
            .histogram {
                width: 100%;
                height: 240px;
                // /deep/ canvas {
                //     width: 110% !important;
                //     height: 100% !important;
                //     left: -2% !important;
                // }
            }
            .timenode {
                width: 90%;
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        .timeSlot {
            color: #808080;
            font-size: 24px;
            margin: 20px 0;
        }
        .percentage {
            color: #4F4F4F;
            font-size: 34px;
        }
        .tips {
            width: 100%;
            display: flex;
            justify-content: space-around;
            .less,
            .greater {
                color: #808080;
                font-size: 14px;
                position: relative;
                margin: 30px 0;
            }
            .less::before,
            .greater::before{
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                left: -15px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 5px;
            }

            .less::before{
                background-color: #D330FF;
            }
            .greater::before{
                background-color: #22BE96;
            }
        }
        .tipsNum {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .mini,
            .average,
            .max {
                width: 32%;
                padding: 5px 10px;
                border-radius: 5px;
                .title {
                    display: flex;
                    align-items: center;
                    color: #808080;
                    .tipicon {
                        width: 5px;
                        height: 10px;
                        border-radius: 5px;
                        margin-right: 5px;
                    }
                }
                .num {
                    color: #282828;
                    font-size: 24px;
                    text-align: center;
                    margin-top: 10px;
                }
            }
            .mini {
                border: 1px solid #D330FF;
                background: #FEF8FE;
                .tipicon {
                    background: #D330FF;
                }
            }
            .average {
                border: 1px solid #FCB334;
                background: #FFFBF5;
                .tipicon {
                    background: #FCB334;
                }
            }
            .max {
                border: 1px solid #2FBE95;
                background: #F4FBF9;
                .tipicon {
                    background: #2FBE95;
                }
            }
        }
    }
}
</style>
